import React, { useState } from "react";
import { functions, db } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";
import { softDelete } from "../services/firestoreService";
import './DetailsViewModal.css';

const DetailsViewModal = ({ docId, code, details, onClose, onDataUpdate, onRemoveItem }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedContent, setEditedContent] = useState('');
    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [currentAction, setCurrentAction] = useState('');

    const generateText = httpsCallable(functions, "generateText");
    const handleGenerate = async () => {
        try {
            const { data } = await generateText({details: "Create a short text about houses."});
            console.log(data);
        } catch (error) {
            console.error("Error generating text: ", error);
        }
    };

    const handleEdit = () => {
        setIsEditMode(true);
        setEditedContent(details);
    };

    const handleCancelEdit = () => {
        setIsEditMode(false);
    };

    const handleTextareaChange = (event) => {
        setEditedContent(event.target.value);
    };

    const handleDelete = async () => {
        setShowDeleteConfirmation(true);
    };

    const confirmDelete = async () => {
        setCurrentAction("deleting");
        setIsLoading(true);
        setShowDeleteConfirmation(false);
        try {
            await softDelete(docId);
            setMessage("The document has been marked as deleted.");
            setMessageType("success");
            onRemoveItem(docId);
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            console.error("Error performing delete: ", error);
            setMessage("Failed to mark document as deleted.");
            setMessageType("error");            
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    };

    const handleSave = async () => {
        setShowSaveConfirmation(true);
    };

    const confirmSave = async () => {
        setCurrentAction('saving');
        setIsLoading(true);
        setShowSaveConfirmation(false);
        if (docId) {
            const docRef = doc(db, "details", docId);
            try {
                await updateDoc(docRef, { details: editedContent});
                onDataUpdate(docId, editedContent);
                setMessage("Edited text saved successfully.");
                setMessageType("success");
                onDataUpdate();
                setTimeout(() => {
                    onClose();
                }, 2000);
            } catch (error) {
                console.error("Error updating details: ", error);
                setMessage("Failed to update details.")
                setMessageType("error");
            } finally {

                setIsLoading(false);
                setTimeout(() => {
                    setMessage('');
                }, 2000);
            }
        }
    };

    return (
        <div className="details-view-modal-container">
            <div className="inner-view-modal-container">
                <span className="close" onClick={onClose}>&times;</span>
                <h2 className="view-modal-title">Property ID: {code}</h2>
                {message && (
                    <div className={`message ${messageType}`}>
                        {message}
                    </div>
                )}
                <textarea
                    value={isEditMode ? editedContent: details}
                    readOnly={!isEditMode}
                    onChange={handleTextareaChange}
                />
                <div className="details-view-modal-buttons">
                    {!isEditMode && (
                        <>
                            <button className="generate-button" onClick={handleGenerate}>Generate</button>
                            <button className="edit-button" onClick={handleEdit}>Edit</button>
                            <button className="delete-button" onClick={handleDelete}>Delete</button>
                            <button className="cancel-button" onClick={onClose}>Cancel</button>
                        </>
                    )}
                    {isEditMode && (
                        <>
                            <button className="save-button" onClick={handleSave}>Save</button>
                            <button className="cancel-edit-button" onClick={handleCancelEdit}>Cancel</button>
                        </>
                    )}
                </div>
                {showDeleteConfirmation && (
                    <div className="details-view-overlay">
                        <div className="confirmation-dialog">
                            <p>Are you sure you want to delete this document?</p>
                            <button className="delete-button" onClick={confirmDelete}>Delete</button>
                            <button className="cancel-confirmation-button" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
                        </div>
                    </div>
                )}
                {showSaveConfirmation && (
                    <div className="details-view-overlay">
                        <div className="confirmation-dialog">
                            <p>Are you sure you want to edit this file?</p>
                            <button className="save-button" onClick={confirmSave}>Save</button>
                            <button className="cancel-confirmation-button" onClick={() => setShowSaveConfirmation(false)}>Cancel</button>
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div className="details-view-overlay">
                        <div className="loading-message">
                            {currentAction === 'deleting' ? "deleting file..." : "Generating description..."}
                        </div>
                    </div>
                )}
                
            </div>
        </div>
    );
};

export default DetailsViewModal;