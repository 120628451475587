import React from "react";
import './About.css';
import about_img from './images/about-image.jpg';

function About() {
    return (
        <section id="about">
            <div className="about-content-container">
                <div className="about-text-container">
                    <div className="about-text-limit">
                        <h1>About BrainConn</h1>
                        <p>
                            BrainConn is a forward-thinking AI consultancy startup focused on
                            spreading AI knowledge. We offer comprehensive AI workshops, speeches
                            and training sessions designed to make AI knowledge accessible for all.
                        </p>
                        <div className="list-container">
                            <ul className="custom-list">
                                <li>We simplify complex AI concepts making it easy to understand and
                                    apply in various business context.</li>
                                <li>Our training are engaging and hands-on, focusing on transferring
                                    practical skills that can be immediately applied.</li>
                                <li>We provide tailored solutions, understanding that every business
                                    is different and requires a unique approach.</li>
                            </ul>
                        </div>
                        <p>
                            Partnering with BrainConn means gaining a competitive edge, future-proofing
                            your business through technological advancements and embrancing a 
                            productivity revolution. Allow us connect Intelligence Artificial to your
                            business.
                        </p>
                        <div className="about-button-container">
                            <button className="about-custom-button">Connect Today</button>
                        </div>
                    </div>
                </div>
                <div className="about-image-container">
                    <div className="about-image-limit">
                        <img src={about_img} alt="About Image" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;