import React from "react";
import './Hero.css';
import heroVideo from './images/main-animation-12mb.mp4'

function Hero() {
    return (
        <section id="hero">
            <video className="hero-video" 
                autoPlay
                loop
                muted
                playsInline
                style={{ width: '100%', height: 'auto' }}>
                <source src={heroVideo} type="video/mp4" />
            </video>
            <div className="hero-text-overlay">
                <h1>Connecting AI to Business</h1>
                <p>Empowering Your Business with AI Tools and Technologies</p>
            </div>
        </section>
    );
}

export default Hero;