import React from "react";
import './Services.css';
import service_img from './images/service_cards_img.jpg';

function Services() {
    return (
        <section id="services">
            <h1>Our Services</h1>
            <div className="services-main-text">
                <p>
                    BrainConn is a startup that offers a series of services aiming
                    to democratize the knowledge and use of Artificial Intelligence
                    by companies and their people.
                </p>
            </div>
            <div className="services-content-container">
                <div className="services-image-container">
                    <img src={service_img} alt="Service Cards" />
                </div>
                <div className="services-card-container">
                    <div className="services-card">
                        <h3>AI Workshops</h3>
                        <p>Interactive and engaging sessions providing
                            a deep diving into AI and how it can revolutionized
                            you business strategies and processes.
                        </p>
                        <a href="#workshops">Learn More</a>
                    </div>
                    <div className="services-card">
                        <h3>AI Speeches</h3>
                        <p>Inspirational and enlightening speeches about AI, its potential,
                            and the power it holds for corporate competitiveness.
                        </p>
                        <a href="#speeches">Learn More</a>
                    </div>
                    <div className="services-card">
                        <h3>Management Training</h3>
                        <p>Taylored training for leaders to grasp the potentials of AI and
                            align it with the organization's strategic objectives.
                        </p>
                        <a href="#management-training">Learn More</a>
                    </div>
                    <div className="services-card">
                        <h3>Employee Training</h3>
                        <p>Comprehensive training sessions for employees to understand and 
                            efficiently use AI tools in a day-to-day operations.</p>
                        <a href="#employees-training">Learn More</a>
                    </div>
                    <div className="services-card">
                        <h3>Competitive Strategy</h3>
                        <p>Equip your business with competitive strategies to leverage AI for
                            improved efficiency and effectiveness.
                        </p>
                        <a href="#competitive-strategy">Learn More</a>
                    </div>
                    <div className="services-card">
                        <h3>Productivity Growth</h3>
                        <p>We help business unlock new levels of productivity by integrating
                            AI technologies effectively.
                        </p>
                        <a href="#productivity">Learn More</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;