import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Navbar from "./Navbar";
import DetailsViewModal from "./DetailsViewModal";
import './DetailsView.css';

const DetailsView = () => {
    const [details, setDetails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState(null);
    const currentUser = auth.currentUser;
    
    const fetchData = async (userId) => {
        if (currentUser) {
            try {
                const detailsQuery = query(
                    collection(db, "details"),
                    where("userId", "==", currentUser.uid),
                    where("isDeleted", "==", false),
                    where("isProcessed", "==", false)
                );
                const querySnapshot = await getDocs(detailsQuery);
                const detailsData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data() }));
                setDetails(detailsData);
            } catch (error) {
                console.error("Error fetching details: ", error);
            }
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            fetchData(user.uid);
          }
        });
        return () => unsubscribe();
    }, []);

    const handleAnalyze = (document) => {
        setSelectedDetails(document);
        setShowModal(true);
    };

    const removeProcessedItem = (documentId) => {
        setDetails(prevDetails => prevDetails.filter(document => document.id !== documentId));
    };

    return (
        <div>
            <Navbar />
            <div className="details-view-container">
                <div className="inner-details-view-container">
                    <h2 className="details-view-title">Property Details</h2>
                    <table className="details-view-table">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Details</th>
                                <th>Created at</th>
                                <th className="action-header">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((document) => (
                                <tr key={document.id}>
                                    <td>{document.code}</td>
                                    <td>{document.details}</td>
                                    <td>
                                        {document.createdAt?.toDate().toLocaleDateString("en-US")}{" "}
                                        {document.createdAt?.toDate().toLocaleTimeString("en-US")}
                                    </td>
                                    <td className="action-cell">
                                        <button className="analyze-button" onClick={() => handleAnalyze(document)}>Analyze</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <DetailsViewModal
                    docId={selectedDetails.id}
                    code={selectedDetails.code}
                    details={selectedDetails.details} 
                    onClose={() => setShowModal(false)} 
                    onDataUpdate={fetchData}
                    onRemoveItem={removeProcessedItem}

                />
            )}
        </div>
    );
};

export default DetailsView;