import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import './login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            setMessage('Login failed.');
            setMessageType('error')
        }
    };

    return (
        <div className="login-container">
            <div className="inner-container">
                <div className="form-section">
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <h2>PropDesc</h2>
                    </Link>
                    <form onSubmit={handleLogin} className="login-form">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit">Login</button>
                        <p>Don't have an account? <a href="/user-register">Register here</a></p>
                    </form>
                    {message && <p className={messageType === 'success' ? 'success-message' : 'error-message'}>{message}</p>}
                </div>
                    <div className="image-section">
                        <img src="/images/cover.jpeg" alt="Artificial Intelligence" />
                    </div>
            </div>
        </div>
    )
};

export default Login;
