import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Navbar from "./Navbar";
import DetailsUploadModal from './DetailsUploadModal';
import './DetailsUpload.css';

const DetailsUpload = () => {
    const [tableData, setTableData] = useState([
        { code: '', details: '' }
    ]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleAddRow = () => {
        setTableData([...tableData, { code: '', details: '' }]);
    };

    const handleRemoveLastRow = () => {
        if (tableData.length > 0) {
            setTableData(tableData.slice(0, -1));
        }
    };

    const handleEditRow = (index) => {
        setSelectedRow({ index, ...tableData[index] });
        setIsModalOpen(true);
    };

    const handleSaveRow = (editedRow) => {
        const updatedData = [...tableData];
        updatedData[selectedRow.index] = { ...editedRow };
        setTableData(updatedData);
        setIsModalOpen(false);
    };

    const handleUpload = async (row, index) => {
        if (!auth.currentUser) {
            console.error("No user logged in!");
            return;
        }
        
        const newRow = {
            ...row,
            userId: auth.currentUser.uid,
            isProcessed: false,
            isDeleted: false,
            createdAt: serverTimestamp()
        };
        try {
            const docRef = await addDoc(collection(db, "details"), newRow);
            console.log("Document written with ID: ", docRef.id);
            const updatedData = [...tableData];
            updatedData.splice(index, 1);
            setTableData(updatedData);
            setMessage("Details successfully uploaded to the database.");
            setTimeout(() => setMessage(''), 2000);
        } catch (error) {
            console.error("Error saving details: ", error);
            setMessage("Saving details into the database failed.");
            setTimeout(() => setMessage(''), 2000);
        }
    };

    const handleUploadAll = async () => {
        const nonEmptyRows = tableData.filter(row => row.code.trim() !== '' || row.details.trim() !== '');
        if (nonEmptyRows.length === 0) {
            setMessage("No data to upload.");
            setTimeout(() => setMessage(''), 2000);
            return;
        }

        if (!auth.currentUser) {
            console.error("No user logged in!");
            setMessage("User not authenticated.");
            setTimeout(() => setMessage(''), 2000);
            return;
        }

        try {
            for (const row of nonEmptyRows) {
                const newRow = {
                    ...row,
                    userId: auth.currentUser.uid,
                    isProcessed: false,
                    isDeleted: false,
                    createdAt: serverTimestamp()
                };
                await addDoc(collection(db, "details"), newRow);
            }
            setTableData([]);
            setMessage("All details successfully uploaded to database.")
        } catch (error) {
            console.error("Error uploading details: ", error);
            setMessage("Uploading ");
        }
        setTimeout(() => setMessage(''), 2000);
    };

    return (
        <div>
            <Navbar />
            <div className="details-container">
                <div className="details-title">
                    <h2>Upload Details</h2>
                </div>
                {message && (
                    <div className={`message-box ${message.includes("failed") ? "error-message" : "success-message"}`}>
                        {message}
                    </div>
                )}
                <div className="table-container">
                    <table className="details-table">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Details</th>
                                <th className="action-header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.code}</td>
                                    <td>{row.details}</td>
                                    <td className="action-cell">
                                        <button className="compose-button" onClick={() => handleEditRow(index)}>Compose</button>
                                        <button className="upload-button" onClick={() => handleUpload(row, index)}>Upload</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="table-buttons">
                        <div className="table-button-group">
                            <button className="add-row-button" onClick={handleAddRow}>Add</button>
                            <button className="remove-row-button" onClick={handleRemoveLastRow}>Remove</button>
                            <button className="upload-all-button" onClick={handleUploadAll} >Upload All</button>
                        </div>
                    </div>
                </div>
                {isModalOpen && (
                    <DetailsUploadModal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSave={handleSaveRow}
                        rowData={selectedRow}
                    />
                )}
            </div>
            <div className="details-warning">
                <p>Warning: If you leave this screen, data you have in the table will be erased.</p>
            </div>
        </div>
    );
};

export default DetailsUpload;
