import React from "react";
import "./ContactModal.css";

const ContactModal = ({ showModal, closeModal, isSuccess, message }) => {
    return (
        <div className={`contact-modal ${showModal ? 'visible' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <div className={`modal-body &{isSuccess ? 'success' : 'error'}`}>
                    <p>{message}</p>
                    {isSuccess && <p>Your message was sent successfully!</p>}
                    {!isSuccess && <p>Failed to send your message. Please try again later.</p>}
                </div>
            </div>
        </div>
    );
};

export default ContactModal;