import React from "react";
import HomeNavbar from './HomeNavbar';
import Hero from './Hero';
import About from './About';
import Services from './Services';
import Solutions from './Solutions';
import Contact from './Contact';
import Footer from './Footer'

const HomePage = () => {
  return (
    <div>
      <HomeNavbar />
      <Hero />
      <About />
      <Services />
      <Solutions />
      <Contact />
      <Footer />
    </div>
  );
}

export default HomePage;