import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAgEaS4kJvyJnqS_LKTNymM4_TWpBJJGno",
  authDomain: "propdesc-1612b.firebaseapp.com",
  projectId: "propdesc-1612b",
  storageBucket: "propdesc-1612b.appspot.com",
  messagingSenderId: "326384680261",
  appId: "1:326384680261:web:3c56b40e2ceb4662d0122c",
  measurementId: "G-K14S8CJT1N"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, db, functions };
