import React from "react";
import './Footer.css'

function Footer() {
    return (
        <section id="footer">
            <div className="footer-container">
                <h5>© BrainConn 2023 – All Rights Reserved</h5>
            </div>
        </section>
    );
}

export default Footer;