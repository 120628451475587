import React, { useState } from "react";
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import './Contact.css'
import ContactModal from "./ContactModal";

function Contact() {
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        phone: "",
        message: ""
    });

    const [modalData, setModalData] = useState({
        showModal: false,
        isSuccess: false,
        modalMessage: '',
    });

    const openModal = (isSuccess, message) => {
        setModalData({
            showModal: true,
            isSuccess,
            modalMessage: message,
        });
    };

    const closeModal = () => {
        setModalData({
            showModal: false,
            isSuccess: false,
            modalMessage: '',
        });
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFormSumbmit = async (e) => {
        e.preventDefault();
        try {
            const newRow = {
                ...formData,
                createdAt: serverTimestamp()
            };
            const docRef = await addDoc(collection(db, "contactForm"), newRow);
            setFormData({
                name: "",
                company: "",
                email: "",
                phone: "",
                message: ""
            });
            console.log("Form data stored with ID: ", docRef.id);
            console.log("Form data: ", newRow);
            openModal(true, "Your message was sent successfully.");
        } catch (error) {
            console.error("Error saving data.", error);
            openModal(false, "Failed to send your message. Please, try again.");
        }
    };

    return (
        <section id="contact">
            <h1>Get Start With AI Today</h1>
            <p>Let's talk about your projet</p>
            <div className="contact-content-container">
                <form className="contact-form" onSubmit={handleFormSumbmit}>
                    <div className="contact-form-row">
                        <input className="contact-input-field"
                            type="text"
                            name="name"
                            placeholder="Name*"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            />
                        <input className="contact-input-field"
                            type="text"
                            name="company"
                            placeholder="Company Name*"
                            value={formData.company}
                            onChange={handleInputChange}
                            required
                            />
                    </div>
                    <div className="contact-form-row">
                        <input className="contact-input-field"
                            type="email"
                            name="email"
                            placeholder="Business email*"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <input className="contact-input-field"
                            type="tel"
                            name="phone"
                            placeholder="Phone (Optional)"
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="contact-form-row">
                        <textarea
                            name="message" 
                            placeholder="How can we help you?"
                            value={formData.message}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="contact-form-row">
                        <button className="contact-custom-button" type="submit">Send Message</button>
                    </div>
                </form>
            </div>
            <ContactModal
                showModal={modalData.showModal}
                closeModal={closeModal}
                isSuccess={modalData.isSuccess}
                message={modalData.modalMessage}
            />
        </section>
    );
}

export default Contact;