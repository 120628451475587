import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import './Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };

    return (
        <div className="navbar-container">
            <nav className="navbar">
                <div className="navbar-brand">
                    <h2>PropDesc</h2>
                </div>
                <div className="navbar-menu">
                    <Link to="/user_dashboard/details-upload" className="navbar-item">Upload</Link>
                    <Link to="/user_dashboard/details-view" className="navbar-item">Details</Link>
                    <Link to="/user_dashboard/" className="navbar-item">Descriptions</Link>
                    <button onClick={handleLogout} className="navbar-item logout-button">Logout</button>
                </div>
            </nav>
            <div className="main-content">
                <Outlet />
            </div>
        </div>
    );
};

export default Navbar;