import React, {useState } from "react";
import "./DetailsUploadModal.css";

const DetailsUploadModal = ({ isOpen, onClose, onSave, rowData }) => {
    const [data, setData] = useState(rowData);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        onSave(data);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
            <span className="close" onClick={onClose}>&times;</span>
                <h2 className="modal-title">Insert Details</h2>
                <textarea className="textarea-code" 
                    name="code"
                    placeholder="Insert a code..."
                    value={data.code}
                    onChange={handleChange}
                />
                <textarea className="textarea-details"
                    name="details"
                    placeholder="Insert your property's details..."
                    value={data.details}
                    onChange={handleChange}
                />
                <div className="modal-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="cancel-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default DetailsUploadModal;