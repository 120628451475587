import React from "react";
import './Solutions.css';

function Solutions() {
    return (
        <section id="solutions">
            <h1>AI Solutions</h1>
            <p>
                Get to know the most impressive Artificial Intelligence use case.
            </p>
                <div className="solutions-card-container">
                    <div className="solutions-card">
                        <h3>Marketing and Sales</h3>
                        <ul className="solutions-custom-list">
                            <li>Content generation</li>
                            <li>Personalization</li>
                            <li>Lead generation</li>
                            <li>Conversational marketing</li>
                            <li>Market research</li>
                            <li>Content summarization</li>
                        </ul>
                        <a href="#marketing-sales">Learn More</a>
                    </div>
                    <div className="solutions-card">
                        <h3>E-commerce</h3>
                        <ul className="solutions-custom-list">
                            <li>Customer support chatbots</li>
                            <li>Customer feedback analysis</li>
                            <li>Personlized shopping experience</li>
                            <li>Recommendations and notifications</li>
                        </ul>
                        <a href="#e-commerce">Learn More</a>
                    </div>
                    <div className="solutions-card">
                        <h3>Retail</h3>
                        <ul className="solutions-custom-list">
                            <li>Personlized marketing</li>
                            <li>Customer service and support</li>
                            <li>Customer data analysis</li>
                        </ul>
                        <a href="#retail">Learn More</a>
                    </div>
                </div>
                <div className="solutions-card-container">
                    <div className="solutions-card">
                        <h3>Logistics</h3>
                        <ul className="solutions-custom-list">
                            <li>Chatbots and automated responses for customer support</li>
                            <li>Chatbots and automated responses for supplier communication</li>
                            <li>Route planning and scheduling</li>
                        </ul>
                        <a href="#logistics">Learn More</a>
                    </div><div className="solutions-card">
                        <h3>FinTech</h3>
                        <ul className="solutions-custom-list">
                            <li>Virtual assistant for customer support</li>
                            <li>Financial products recommendations</li>
                            <li>Loans applicants support</li>
                            <li>Automated Q&A sessions based on internal knowledge base</li>
                        </ul>
                        <a href="#fintech">Learn More</a>
                    </div>
                    <div className="solutions-card">
                        <h3>Insurance</h3>
                        <ul className="solutions-custom-list">
                            <li>Customer support in filling insurance claims</li>
                            <li>Policy recommendations</li>
                            <li>Insurance education</li>
                        </ul>
                        <a href="#insurance">Learn More</a>
                    </div>
                </div>
                <div className="solutions-card-container">
                    <div className="solutions-card">
                        <h3>Health</h3>
                        <ul className="solutions-custom-list">
                            <li>Healthcare chatbots and virtual assistants</li>
                            <li>Appointment scheduling</li>
                            <li>Patient reports generation</li>
                            <li>Treatment recommendations</li>
                            <li>Medical research</li>
                        </ul>
                        <a href="#health">Learn More</a>
                    </div><div className="solutions-card">
                        <h3>Agriculture</h3>
                        <ul className="solutions-custom-list">
                            <li>Virtual farm advisor</li>
                            <li>Crop diagnosis and recommendations</li>
                            <li>Pest identification and disease management</li>
                        </ul>
                        <a href="#agriculture">Learn More</a>
                    </div>
                    <div className="solutions-card">
                        <h3>Sport & Wellness</h3>
                        <ul className="solutions-custom-list">
                            <li>Training plan generation</li>
                            <li>Nutrition and workout planning</li>
                            <li>Mental health support with chatbots</li>
                            <li>Virtual coaches</li>
                        </ul>
                        <a href="#link">Learn More</a>
                    </div>
                </div>
        </section>
    )
}

export default Solutions;