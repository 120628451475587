import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

export const markAsProcessed = async (documentId) => {
    const docRef = doc(db, "properties", documentId);
    await updateDoc(docRef, {
        is_processed: true
    });
};

export const softDelete = async (documentId) => {
    const docRef = doc(db, "details", documentId);
    await updateDoc(docRef, {
        isDeleted: true
    });
};
