import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './components/website/HomePage';
import Login from './components/login';
import UsersRegister from './components/UsersRegister';
import Dashboard from './components/user_dashboard/Dashboard';
import DetailsUpload from './components/user_dashboard/DetailsUpload';
import DetailsView from './components/user_dashboard/DetailsView';

function App() {
  
  return (
    <Router>
      <div className='App'>
        <div className='main-content'>
          <div className='content'>
            <Routes>
              <Route path='/' element={<HomePage />} />
                <Route path='/login' element={<Login />} />
              {/* <Route path='/user-register' element={<UsersRegister />} /> */}
              <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/user_dashboard/details-upload' element={<DetailsUpload />} />
                <Route path='/user_dashboard/details-view' element={<DetailsView />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
