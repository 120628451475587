import React from "react";
import './HomeNavbar.css';

const HomeNavbar = () => {

  return (
    <header id="home-navbar">
    <div className="home-navbar-container">
      <nav className="home-navbar">
        <div className="home-brand">
          <a href="#home-navbar" id="brainconn-link" className="brand-link">
              <h1>BrainConn</h1>
            </a>
        </div>
        <div className="home-navbar-menu">
          <ul>
          <li><a href="#about" className="home-navbar-item">About</a></li>
            <li><a href="#services" className="home-navbar-item">Services</a></li>
            <li><a href="#solutions" className="home-navbar-item">Solutions</a></li>
            <li><a href="#contact" className="home-navbar-item">Contact</a></li>
            <li><a href="/login" className="home-navbar-item">Login</a></li>
          </ul>
        </div>
      </nav>
    </div>
    </header>
  );
}

export default HomeNavbar;
