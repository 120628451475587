import React from "react";
import Navbar from "./Navbar";

const Dashboard = () => {
    return (
        <div>
            <Navbar />
        </div>
    );
};

export default Dashboard;